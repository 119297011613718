import Vue from "vue";
import App from "./App.vue";
import "@/assets/app.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPlay,
  faStop,
  faRecordVinyl,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faMicrophone } from "@fortawesome/free-solid-svg-icons";
import AudioRecorder from "vue-audio-recorder";

Vue.use(AudioRecorder);

library.add(faPlay, faStop, faRecordVinyl, faMicrophone);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
}).$mount("#app");
