<template>
  <div id="app">
    <app-header></app-header>
    <voiceRecord msg="Welcome to Your Vue.js App" />
  </div>
</template>

<script>
import voiceRecord from './components/voiceRecord.vue'
import appHeader from "@/components/appHeader";

export default {
  name: 'App',
  components: {
    voiceRecord,
    appHeader
  }
}
</script>