<template>
  <div class="main">
    <audio-recorder upload-url="some url" :attempts="3" :time="2" :before-recording="callback" :after-recording="callback"
      :before-upload="callback" :successful-upload="callback" :failed-upload="callback" />
  </div>
</template>

<script>
export default {
  name: 'voiceRecord',
  data() {
    return {
      showRecorder: true,
      headers: {
        'X-Custom-Header': 'some data'
      }
    }
  },
  methods: {
    callback(msg) {
      console.debug('Event: ', msg)
    },
    toggle() {
      this.showRecorder = !this.showRecorder
    }
  },
}
</script>



<style scoped>
.toggle {
  cursor: pointer;
  margin: 20px;
}

.main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* This makes the div take up the full viewport height */
}

.ar-recorder__duration {
  color: #AEAEAE;
  font-size: 32px;
  font-weight: 500;
  margin-top: 33px !important;
  margin-bottom: 16px;
}
</style>
